import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/amahony/Sites/reactberry/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Loader from '.';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "loader"
    }}>{`Loader`}</h1>
    <Props of={Loader} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Loader />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Loader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Loader mdxType="Loader" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      